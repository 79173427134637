import type { MyStoreInfo } from "~/commerce-sap/.server/sessions.server";

import type { OrderEntry } from "~/commerce-sap/.server/api/generated/__generated_apis";
import InStockIcon from "~/components/ui/icons/in-stock-icon";
import LowStockIcon from "~/components/ui/icons/low-stock-icon";
import OutOfStockIcon from "~/components/ui/icons/out-of-stock-icon";

import { getStockLevelStatus } from "../../_product-details+/components/pdp-store-finder";

type CartProductStockInfoProps = {
  productBasketEntry: OrderEntry;
  availableQty: number;
  myStoreInfo: MyStoreInfo | undefined;
};

const getStoreStockLevel = (stockStatus: string, storeName: string): string => {
  switch (stockStatus) {
    case "inStock":
      return `In stock at ${storeName}`;
    case "lowStock":
      return `Low stock at ${storeName}`;
    default:
      return `Out of stock at ${storeName}`;
  }
};

const CartProductStockInfo = ({
  productBasketEntry,
  availableQty,
  myStoreInfo,
}: CartProductStockInfoProps) => {
  const stockLevelStatus = getStockLevelStatus(availableQty);
  const storeStockLabel = getStoreStockLevel(
    stockLevelStatus,
    myStoreInfo?.store?.displayName ?? "",
  );
  const { availableForClickCollect } = productBasketEntry.product || {};

  const stockIcon = availableForClickCollect ? (
    stockLevelStatus === "inStock" ? (
      <InStockIcon />
    ) : stockLevelStatus === "lowStock" ? (
      <LowStockIcon />
    ) : (
      <OutOfStockIcon />
    )
  ) : (
    <OutOfStockIcon />
  );

  return (
    <div className="flex items-center gap-1">
      {stockIcon}
      {availableForClickCollect
        ? `${storeStockLabel}`
        : "Not available for Collection"}
    </div>
  );
};

export default CartProductStockInfo;
