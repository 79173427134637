import { memo } from "react";

import { Link } from "@remix-run/react";

import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import type { BapcorFitment } from "~/commerce-sap/.server/api/generated/__generated_apis";
import ProductTileImage from "~/commerce-sap/routes/($locale)+/_product-list+/components/product-tile/product-tile-image";
import useProductPrimaryImage from "~/commerce-sap/routes/($locale)+/_product-list+/hooks/use-product-primary-image";
import { IsGiftCard } from "~/commerce-sap/shop/utils/giftcard";
import { ADD_TO_CART_EVENT, useGTMTracker } from "~/google-tagmanager";
import { cn } from "~/lib/ui";

import { useProductUrl } from "../../hooks/product-url";
import GiftcardShowCTA from "./gift-card-show-CTA";
import ProductTileCartCTA from "./product-tile-cart-CTA";
import { useProductTileData } from "./use-product-tile-data";

type ProductTileProps = {
  product: AlgoliaSearchResultHit;
  fitmentData:
    | {
        [key: string]: BapcorFitment[] | undefined;
      }
    | Promise<{
        [key: string]: BapcorFitment[] | undefined;
      }>;
  showSuitability: boolean;
  list_id: string;
  list_name: string;
  index: number;
  isLoyalityMember?: boolean;
  className?: string;
  isCarousel?: boolean;
};

const ProductTile = ({
  product,
  fitmentData,
  showSuitability,
  list_id,
  list_name,
  index = 0,
  isLoyalityMember,
  className,
  isCarousel = false,
}: ProductTileProps) => {
  const productURL = useProductUrl(product);

  const linkTo =
    list_id && list_name && index
      ? `${productURL}?list_id=${list_id}&list_name=${list_name}&index=${index}`
      : productURL;
  const { getHystoryState } = useProductTileData(product);
  const { primaryImage } = useProductPrimaryImage({
    images: product.images ?? [],
  });
  const { selectItemEvent, cartEventsEvent } = useGTMTracker();
  const isGiftCard = IsGiftCard(product);
  // make product code as upperCase because we always receive the product code as upperCase in the basket.
  product.code = product.code.toUpperCase();

  const addToCartEvent = () => {
    cartEventsEvent(ADD_TO_CART_EVENT, [
      {
        code: product.code,
        name: product.name,
        brand: product.brand.name,
        price: product.price?.[0],
        quantity: 1,
        list_id,
        list_name,
        index,
      },
    ]);
  };
  return (
    <div
      className={cn(
        "flex w-full flex-col no-underline hover:no-underline sm:py-5 md:w-1/3 md:p-1.5 lg:w-1/4 lg:p-3",
        className,
        isCarousel ? "carousel-product-tile" : "listing-product-tile",
      )}
      key={product?.code}
    >
      <div
        className={cn(
          "lg:p flex h-full flex-col px-[9px] pb-4 pt-4",
          isCarousel ? "border-light-gray  rounded-lg border" : "shadow-tile",
        )}
      >
        <div className="flex-grow">
          <div className="flex w-full flex-row gap-2 md:flex-col">
            <ProductTileImage
              product={product}
              linkTo={linkTo}
              list_id={list_id}
              list_name={list_name}
              index={index}
              isCarousel={isCarousel}
            />
            <div className="h-20">
              <div className="pb-2 text-base font-bold">
                <Link
                  state={getHystoryState(primaryImage)}
                  to={linkTo}
                  onClick={() =>
                    selectItemEvent(
                      {
                        code: product.code,
                        name: product.name,
                        brand: product.brand.name,
                        price: product.price[0],
                        index,
                        list_id,
                        list_name,
                      },
                      index,
                      list_id,
                      list_name,
                    )
                  }
                >
                  <span className="md:line-clamp-2">{product.name}</span>
                </Link>
              </div>
              <Link
                to={`${productURL}#reviews`}
                className="no-underline hover:no-underline"
                preventScrollReset
              >
                <div
                  className="min-h-5 pb-2"
                  data-bv-show="inline_rating"
                  data-bv-product-id={product.code}
                ></div>
              </Link>
            </div>
          </div>
          {isGiftCard ? (
            <GiftcardShowCTA
              product={product}
              addToCartEvent={addToCartEvent}
            />
          ) : (
            <ProductTileCartCTA
              product={product}
              fitmentData={fitmentData}
              showSuitability={showSuitability}
              isLoyalityMember={isLoyalityMember}
              addToCartEvent={addToCartEvent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ProductTile);
