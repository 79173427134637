import { useEffect } from "react";

import { json } from "@remix-run/cloudflare";
import { useFetcher } from "@remix-run/react";

import { getProductRecommendations } from "~/lib/product-recommendations";

export const loader = async ({ request, context }: LoaderArgs) => {
  const url = new URL(request.url);
  const sysId = url.searchParams.get("sysId");
  const categoryId = url.searchParams.get("categoryId") ?? "";
  const productIDs = url.searchParams.get("productIDs") ?? "";

  if (!sysId || (!categoryId && !productIDs)) {
    return json(null);
  }

  const recommendations = await getProductRecommendations({
    sysId,
    categoryId,
    productIDs: productIDs.split(","),
    clientIp: context.ip,
  });

  return json(recommendations);
};

export const useProductRecommendations = ({
  sysId,
  categoryId,
  productIDs,
}: {
  sysId: string;
  categoryId?: string;
  productIDs?: string[];
}) => {
  const fetcher = useFetcher<typeof loader>();
  const isLoading = fetcher.state !== "idle" || fetcher.data === undefined;

  const searchParams = new URLSearchParams();
  searchParams.set("sysId", sysId);
  if (categoryId) {
    searchParams.set("categoryId", categoryId);
  } else if (Array.isArray(productIDs) && productIDs.length > 0) {
    searchParams.set("productIDs", productIDs.join(","));
  }

  useEffect(() => {
    fetcher.load(
      `/resources/product-recommendations?${searchParams.toString()}`,
    );
  }, []);

  return { data: fetcher.data, isLoading, fetcher };
};
