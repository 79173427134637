import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { cn } from "~/lib/ui";

import type { CardTextBoxContent } from "../types";
import { getMaxWidthClass } from "../util";
import { ContentfulCTA } from "./contentful-cta";
import { RichTextStyledRenderer } from "./rich-text-styled";
import { TitleUnderline } from "./title-underline";
import TooltipWithRichText from "./tooltip-with-rich-text";

export const CardTextBox = ({
  content,
  className = "",
}: {
  content: CardTextBoxContent;
  className?: string;
}) => {
  const fields = content.fields;
  const inspectorProps = useContentfulInspectorMode();

  // Function to get the rich text content and bullet/marker styling based on content type
  const getRichTextData = (bodyField: any) => {
    if (!bodyField || !bodyField.fields)
      return { content: null, bulletStyling: null, markerStyling: null };

    const contentTypeId = bodyField.sys.contentType.sys.id;

    if (contentTypeId === "richTextBody") {
      return {
        content: bodyField.fields.richText,
        bulletStyling: null,
        markerStyling: null,
      };
    } else if (contentTypeId === "unorderedList") {
      return {
        content: bodyField.fields.unorderedListItems,
        bulletStyling: bodyField.fields.bulletStyling || "default",
        markerStyling: null,
      };
    } else if (contentTypeId === "orderedList") {
      return {
        content: bodyField.fields.orderedListItems,
        bulletStyling: null,
        markerStyling: bodyField.fields.markerStyling || "default",
      };
    } else {
      return { content: null, bulletStyling: null, markerStyling: null };
    }
  };

  const {
    content: richTextContent,
    bulletStyling,
    markerStyling,
  } = getRichTextData(fields.body);

  // Get the Tailwind class for maxWidth
  const maxWidthClass = getMaxWidthClass(fields.maxWidth);

  return (
    <div
      className={cn(
        "card-text-box flex w-full flex-col gap-5",
        className,
        maxWidthClass,
      )}
      {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
    >
      {/* Title */}
      {fields.title && (
        <div>
          <h2 className="font-roboto text-[18px] font-semibold lg:text-[20px] xl:text-[24px]">
            {fields.title}
          </h2>
          <TitleUnderline className="mt-[10px]" />
        </div>
      )}

      {/* Body */}
      {richTextContent && fields.body && (
        <div>
          <RichTextStyledRenderer
            content={richTextContent}
            entryId={fields.body.sys.id}
            contentType={fields.body.sys.contentType.sys.id} // Pass content type here
            bulletStyling={bulletStyling} // Pass the bullet styling prop
            markerStyling={markerStyling} // Pass the marker styling prop
          />
        </div>
      )}

      {/* Tooltip */}
      {fields.toolTipLabel && fields.tooltip && (
        <div className="flex items-center gap-1">
          <TooltipWithRichText
            content={
              <RichTextStyledRenderer
                content={fields.tooltip}
                entryId={fields.tooltip.data.id}
                contentType="tooltip" // Add a default contentType for tooltips
              />
            }
          >
            <span className="cursor-pointer text-sm font-semibold underline">
              {fields.toolTipLabel}
            </span>
          </TooltipWithRichText>
        </div>
      )}

      {/* CTA */}
      {fields.cta && fields.cta.length > 0 && (
        <div className="flex flex-wrap gap-4">
          {fields.cta.map(cta => (
            <ContentfulCTA
              key={cta.sys.id}
              content={cta}
              {...inspectorProps({ entryId: content.sys.id, fieldId: "cta" })}
            />
          ))}
        </div>
      )}
    </div>
  );
};
