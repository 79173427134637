import type { Price } from "~/algolia/algolia.types";
import { MemberIcon } from "~/components/ui/member-icon";
import { cn } from "~/lib/ui";

export function getLowestPrice(
  prices: Price[] | undefined,
): number | undefined {
  if (Array.isArray(prices) && prices.length > 0) {
    // Use reduce to find the lowest price value
    return prices.reduce(
      (min, price) => Math.min(min, price.value),
      prices[0].value,
    );
  }
  return undefined; // Return undefined if the array is empty or not defined
}

export const RRPrice = ({
  price,
  lineThrough,
  className,
}: {
  price: number;
  lineThrough?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={cn([
        "font-sans text-lg font-bold",
        lineThrough
          ? "text-sm text-gray-500 line-through"
          : "brand-primary-black",
        className,
      ])}
    >
      ${price.toFixed(2)}
    </div>
  );
};

export const SalePrice = ({
  price,
  lineThrough,
}: {
  price: number;
  lineThrough?: boolean;
}) => {
  return (
    <div
      className={`font-sans text-lg font-bold leading-[22px] text-sale ${
        lineThrough && "text-sm  line-through"
      }`}
    >
      ${price.toFixed(2)}
    </div>
  );
};

export const MemberPrice = ({
  price,
  isMember,
}: {
  price: number | undefined;
  isMember?: boolean;
}) => {
  return (
    <div
      className={`font-sans font-bold leading-[22px] text-info ${
        isMember ? "flex flex-col" : "flex items-center"
      }`}
    >
      {isMember && <MemberIcon />}

      <div className={isMember ? "text-2xl" : "text-sm"}>
        ${price?.toFixed(2)}
      </div>

      {!isMember && <MemberIcon className="ml-2" />}
    </div>
  );
};
const mapToRange = (
  set: Array<{ key: string; value: number }>,
): Record<string, number> => {
  return set.reduce(
    (acc, { key, value }) => {
      acc[key] = value;
      return acc;
    },
    {} as Record<string, number>,
  );
};
export const PriceRange = ({
  priceRangeMap: _priceRangeMap,
  className,
}: {
  priceRangeMap:
    | Record<string, number>
    | Array<{ key: string; value: number }>
    | undefined;
  className: string | "";
}) => {
  if (!_priceRangeMap) return null;
  const priceRangeMap = Array.isArray(_priceRangeMap)
    ? mapToRange(_priceRangeMap)
    : _priceRangeMap;
  if (!("minPrice" in priceRangeMap) || !("maxPrice" in priceRangeMap)) {
    console.log("priceRangeMap", priceRangeMap);

    return <div className={cn("font-sans", className)}>n/a</div>;
  }
  if (priceRangeMap.minPrice === priceRangeMap.maxPrice) {
    return (
      <div className={cn("font-sans", className)}>
        <span>${priceRangeMap.minPrice.toFixed(2)}</span>
      </div>
    );
  }

  return (
    <div className={cn("font-sans", className)}>
      <span>${priceRangeMap?.minPrice.toFixed(2)}</span>
      <span> - </span>
      <span>${priceRangeMap.maxPrice.toFixed(2)}</span>
    </div>
  );
};
