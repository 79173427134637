import { Suspense, useState } from "react";
import { namedAction } from "remix-utils/named-action";
import { z } from "zod";

import {
  Await,
  useActionData,
  useFetcher,
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
} from "@remix-run/react";
import { json } from "@remix-run/server-runtime";

import { handleLoginAndSync } from "./utils.server";
import {
  commitSession,
  getSessionServerContext,
} from "@commerce/.server/sessions.server";
import { validationError } from "~/components/forms/validationErrorResponse.server";
import { assertIsPost, safeRedirect } from "~/lib/utils-server/http.server";
import { getPageMeta } from "~/seo/servers/seo.server";

import { ChevronLeftIcon } from "@radix-ui/react-icons";
import { withZod } from "@remix-validated-form/with-zod";

import { Button } from "~/components/ui/button";
import { useURL } from "~/contexts";
import type { ActionArgs, LoaderArgs } from "~/lib/loadargs.types";
import { useOnActionCompleted } from "~/lib/remix/fetcher";
import {
  birthDayValidator,
  confirmPasswordValidator,
  emailValidator,
  passwordValidator,
  phoneValidator,
} from "~/lib/schemas/validations";
import { cn } from "~/lib/ui";
import RegisterAlmostDone from "~/routes/($locale)+/_auth+/components/register-almost-done";
import RegisterGetStarted from "~/routes/($locale)+/_auth+/components/register-get-started";

import { useRootLayoutData } from "../_layout";
import { ACCOUNT_LANDING_PAGE_URL } from "../account+/consts";

export const nameRegex = /^[a-zA-Z0-9'\- ]+$/;

export const registerGetStartedSchema = z.object({
  password: passwordValidator,
  confirmPassword: z.string().min(1, "This field is required"),
  firstName: z
    .string()
    .min(1, "This field is required")
    .regex(nameRegex, "Invalid characters in first name"),
  lastName: z
    .string()
    .min(1, "This field is required")
    .regex(nameRegex, "Invalid characters in last name"),
  email: emailValidator,
});

export const registerAlmostDoneSchema = z.object({
  "g-recaptcha-response": z.string().min(1, "ReCAPTCHA is required"),
  captchaToken: z.string().min(1, "ReCAPTCHA is required"),
  captchaRequired: z.string(),
  regToken: z.string().min(1),
  birthDay: birthDayValidator,
  phoneNumber: phoneValidator,
  state: z.string().min(1, "Please select your state"),
  preferredStore: z.string().min(1, "Please select your store"),
  termsAndConditionsAgreement: z
    .string({ errorMap: () => ({ message: "Select checkbox to continue." }) })
    .superRefine((val, ctx) => {
      if (val === "off") {
        ctx.addIssue({
          code: "custom",
          message: "Select checkbox to continue.",
        });
      }
    }),
});

const refinePasswordValidationSchema = () => {
  return registerGetStartedSchema.refine(
    data => data.password === data.confirmPassword,
    confirmPasswordValidator,
  );
};

export const registerGetStartedValidator = withZod(
  refinePasswordValidationSchema(),
);
export const registerAlmostDoneValidator = withZod(registerAlmostDoneSchema);
export const registerValidator = withZod(
  registerAlmostDoneSchema.merge(registerGetStartedSchema),
);

export const loader = async ({ context }: LoaderArgs) => {
  const {
    env: { RECAPTCHA_SECRET_KEY },
  } = getSessionServerContext();

  const policies = context.auth.getAccountPolicies();
  const regToken = context.auth.initRegister();
  const stores = context.shop.getAllStores();

  const data = await Promise.all([policies, regToken, stores]);

  const seo = getPageMeta({
    type: "register",
    options: {
      title: "Register",
      description: "The Register page",
    },
    robots: {
      noFollow: false,
      noIndex: true,
    },
  });

  return {
    seo,
    RECAPTCHA_SECRET_KEY,
    requireCaptcha: data[0].requireCaptcha,
    regToken: data[1]?.regToken,
    stores: data[2],
  };
};

export const action = async ({ request, context }: ActionArgs) => {
  return namedAction(request, {
    async registerUser() {
      assertIsPost(request);

      const validation = await registerValidator.validate(
        await request.clone().formData(),
      );

      if (validation.error) {
        return validationError(validation.error, validation.submittedData);
      }

      const { auth, session } = context;

      try {
        const registerRequestPayload = {
          password: validation.data.password,
          email: validation.data.email,
          profile: {
            firstName: validation.data.firstName,
            lastName: validation.data.lastName,
          },
          data: {
            mobileNumber: validation.data.phoneNumber,
            dateOfBirth: validation.data.birthDay || null,
            preferredState: validation.data.state,
            preferredStore: validation.data.preferredStore,
          },
          consentGranted: validation.data.termsAndConditionsAgreement === "on",
          isLoyaltyconsentGranted: true,
          captchaRequired: validation.data.captchaRequired === "true",
          captchaType: "recaptcha",
          captchaToken: validation.data["g-recaptcha-response"] || "",
          regToken: validation.data?.regToken,
          subscribeMail: false,
        };

        await auth.register(registerRequestPayload);

        const user = await handleLoginAndSync(
          context,
          validation.data.email,
          validation.data.password,
        );
        session.flash("loginFlashMessage", {
          title: `Welcome, ${user.firstName}`,
          message:
            "You are now an Autobarn Accelerate Rewards member. Start shopping and enjoy the ride!",
        });
        return safeRedirect(ACCOUNT_LANDING_PAGE_URL, {
          headers: {
            "Set-Cookie": await commitSession(),
          },
        });
      } catch (e) {
        if (e instanceof Error) {
          console.log(e);
          console.log(e.stack || e);

          return validationError(e.message, validation.submittedData);
        }
        throw e;
      }
    },

    async validateUserSecondaryData() {
      assertIsPost(request);
      const validation = await registerAlmostDoneValidator.validate(
        await request.formData(),
      );

      if (validation.error)
        return validationError(validation.error, validation.submittedData);

      return json(validation.data);
    },
    async validateUserMainData() {
      assertIsPost(request);
      const validation = await registerGetStartedValidator.validate(
        await request.formData(),
      );

      if (validation.error)
        return validationError(validation.error, validation.submittedData);

      return json(validation.data);
    },
  });
};

type RegisterStep = "get-started" | "almost-done";

type Props = {
  className?: string;
  isFlyoutMode?: boolean;
  onBackButtonClick?: () => void;
  customerEmail?: string;
};

export type RegisterState = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  birthDay?: string;
  phoneNumber: string;
  state: string;
  preferredStore: string;
  termsAndConditionsAgreement: string;
  regToken?: string;
  captchaRequired: string;
  "g-recaptcha-response": string;
};

const Register = ({
  className,
  isFlyoutMode = false,
  onBackButtonClick: _onBackButtonClick,
  customerEmail: _customerEmail,
}: Props) => {
  const transition = useNavigation();
  const fetcher = useFetcher<typeof action>({ key: "register-verify" });
  const actionData = useActionData<typeof action>() || fetcher.data;
  const data = useLoaderData<typeof loader>();
  const location = useLocation();
  const customerEmail = _customerEmail || location.state?.customerEmail;
  const [registerStep, setRegisterStep] = useState<RegisterStep>("get-started");
  const { user } = useRootLayoutData();
  const navigate = useNavigate();
  const url = useURL();
  const onBackButtonClick =
    _onBackButtonClick ||
    (registerStep === "almost-done"
      ? () => {
          setRegisterStep("get-started");
          navigate(url("/register"));
        }
      : () => navigate(url("/login")));

  useOnActionCompleted("validateUserMainData", () => {
    window.scrollTo(0, 0);
    setRegisterStep("almost-done");
  });

  const isLoading = transition.state !== "idle" || fetcher.state !== "idle";
  return (
    <Suspense>
      <Await resolve={data}>
        {resolved =>
          !user && (
            <div
              className={cn(
                className,
                "flex flex-col justify-center overflow-y-hidden",
                !isFlyoutMode &&
                  "bg-white sm:px-6 sm:pt-[134px] md:px-8 md:pt-[80px] lg:px-10 lg:pt-[134px] xl:px-[95px]",
              )}
            >
              <div className=" flex cursor-pointer items-center px-6 font-bold underline">
                <Button
                  variant="link"
                  size="1"
                  onClick={onBackButtonClick}
                  className="p-0 font-bold"
                >
                  <ChevronLeftIcon width="16px" height="16px" /> <p>Back</p>
                </Button>
              </div>
              <div
                className={cn(
                  "flex  h-full w-full flex-col space-y-8",
                  !isFlyoutMode && "flex-row gap-6",
                )}
              >
                <div className="px-6">
                  {registerStep === "get-started" && (
                    <RegisterGetStarted
                      fetcher={fetcher}
                      actionData={actionData}
                      isLoading={isLoading}
                      action={"/register/?action=validateUserMainData"}
                      defaultValues={{
                        email: customerEmail,
                      }}
                      onEdit={onBackButtonClick}
                    />
                  )}
                  {registerStep === "almost-done" && (
                    <RegisterAlmostDone
                      regToken={resolved.regToken}
                      requireCaptcha={resolved.requireCaptcha}
                      recaptchaSecret={resolved.RECAPTCHA_SECRET_KEY}
                      fetcher={fetcher}
                      stores={resolved.stores}
                      actionData={actionData}
                      isLoading={isLoading}
                      handleBackButton={() => setRegisterStep("get-started")}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        }
      </Await>
    </Suspense>
  );
};

export default Register;
