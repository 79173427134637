import { useEffect } from "react";
import toast from "react-hot-toast";

import { Link } from "@remix-run/react";

import { Cross1Icon } from "@radix-ui/react-icons";

import { useMediaScreen } from "~/lib/utils/screens";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";

import { ACCOUNT_LANDING_PAGE_URL } from "../../account+/consts";

const LoginFlash = () => {
  const { loginFlashMessage } = useRootLayoutData();
  const { isMobile } = useMediaScreen();
  useEffect(() => {
    if (loginFlashMessage) {
      toast.custom(
        t => (
          <div className="flex rounded-lg border-2 border-success bg-success-lite p-3">
            <div>
              <p>{loginFlashMessage.title}</p>
              <p>{loginFlashMessage.message}</p>
              <Link
                to={ACCOUNT_LANDING_PAGE_URL}
                onClick={() => toast.remove(t.id)}
              >
                View your account
              </Link>
            </div>
            <div className="h-fit cursor-pointer">
              <Cross1Icon onClick={() => toast.remove(t.id)} />
            </div>
          </div>
        ),
        {
          duration: 5000,
          position: isMobile ? "top-center" : "top-left",
          id: "loginFlash",
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginFlashMessage]);
  return null;
};

export default LoginFlash;
