import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import { getMaxWidthClass } from "~/contentful/util";
import { usePick } from "~/lib/utils/screens";
import ProductTile from "~/routes/($locale)+/product-list+/components/product-tile/product-tile";
import { useProductRecommendations } from "~/routes/($locale)+/resources+/product-recommendations";

import { Carousel } from "../ui/carousel";
import { Skeleton } from "../ui/skeleton";
import { H2 } from "../ui/text";

const getSlidesPerView = (
  maxWidth?: 1280 | 1920 | 2560 | 768,
): [number, number, number, number] => {
  if (maxWidth === 768) {
    return [1.1, 3, 3, 3];
  }

  return [1.1, 3, 4.2, 4.2];
};

type Props = {
  sysId: string;
  fields:
    | {
        name: string;
        heading?: string;
        categoryId: string;
        maxWidth?: 1280 | 1920 | 2560 | 768;
      }
    | {
        name: string;
        heading?: string;
        productIDs: string[];
        maxWidth?: 1280 | 1920 | 2560 | 768;
      };
};

const ProductRecommendations = ({ sysId, fields }: Props) => {
  const pick = usePick();

  const { isLoading, data } = useProductRecommendations({
    sysId: sysId,
    categoryId: "categoryId" in fields ? fields.categoryId : "",
    productIDs: "productIDs" in fields ? fields.productIDs : [],
  });

  if (isLoading) return <Skeleton className="h-84 w-full md:h-[500px]" />;

  if (!data || !data.algoliaResult?.hits?.length) return null;

  const maxWidthClass = getMaxWidthClass(fields.maxWidth);

  const sortedAlgoliaResultHits =
    "productIDs" in fields
      ? fields.productIDs
          .flatMap(id => data.algoliaResult.hits.filter(hit => hit.code === id))
          .filter(Boolean)
      : data.algoliaResult.hits;

  return (
    <div className={`product-recommender ${maxWidthClass} mx-auto`}>
      <div className="mb-6">
        {fields.heading && (
          <H2 className="font-roboto text-[26px] font-semibold uppercase leading-lg text-brand-primary-black lg:text-[32px]">
            {fields.heading}
          </H2>
        )}
      </div>
      <Carousel
        spaceBetween={12}
        slidesPerView={pick(getSlidesPerView(fields.maxWidth))}
        centeredSlides={false}
        useBubblesNavigation
        watchSlidesProgress
        watchOverflow
        loop={false}
        slideClassName="self-stretch px-1"
        slideProps={{
          style: { height: "auto" },
        }}
      >
        {sortedAlgoliaResultHits.map(
          (hit: AlgoliaSearchResultHit, i: number) => (
            <ProductTile
              product={hit}
              key={`recommendation-${hit.code}-${i}`}
              list_id="recommendations"
              list_name="Recommendations"
              index={i}
              fitmentData={data.fitmentData ?? {}}
              showSuitability={data.showSuitability ?? false}
              className="h-full w-full self-stretch px-0 py-[0] text-left md:w-full lg:w-full"
              isCarousel={true}
            />
          ),
        )}
      </Carousel>
    </div>
  );
};

export default ProductRecommendations;
