import { useMemo, useState } from "react";
import toast from "react-hot-toast";

import { Link, useNavigate } from "@remix-run/react";

import type { OrderEntry } from "~/commerce-sap/.server/api/generated/__generated_apis";
import ToastSuccess from "~/components/toasts/toast-success";
import { CartStyledIcon } from "~/components/ui/icons/cart-styled-icon";
import { TriButton } from "~/components/ui/tri-button";
import { useOnCompleted } from "~/lib/remix/fetcher";
import { cn } from "~/lib/ui";
import { useMediaScreen } from "~/lib/utils/screens";
import ProductCountAdjust from "~/routes/($locale)+/_product-details+/components/product-count-adjust";
import { useBasket } from "~/routes/($locale)+/resources+/hooks/useBasket";

import { useBasketActions } from "../../resources+/basket";
import ProductQuantitySelector from "./product-quantity-selector";

type Props = {
  productId: string;
  isOutOfStock?: boolean;
  triBtnClassName?: string;
  selectTriggerClassName?: string;
  openMiniCart?: boolean;
  addToCartEvent?: () => void;
};
const AddToCartCTA = ({
  isOutOfStock,
  productId,
  triBtnClassName,
  selectTriggerClassName,
  openMiniCart,
  addToCartEvent,
}: Props) => {
  const [productQuantity, setProductQuantity] = useState<string>("1");
  const navigate = useNavigate();
  const basket = useBasketActions();
  const { isMobile } = useMediaScreen();
  const currentBasket = useBasket()?.basket;
  const productInCart = useMemo(() => {
    return currentBasket?.entries?.find(
      (entry: OrderEntry) => entry.product?.code === productId,
    );
  }, [currentBasket?.entries, productId]);

  const isLoading = basket.fetcher.state !== "idle";

  useOnCompleted(data => {
    if (
      data &&
      "product" in data &&
      data.product.name &&
      "successfullyAdded" in data &&
      data.successfullyAdded
    ) {
      toast.custom(
        t => (
          <ToastSuccess
            t={t}
            message={`${data.product.name} added to your cart`}
          >
            <Link
              to="cart"
              className="text-sm font-bold underline"
              onClick={() => toast.remove(t.id)}
            >
              View Cart
            </Link>
          </ToastSuccess>
        ),
        {
          position: isMobile ? "top-center" : "top-right",
          duration: 3000,
        },
      );

      if (openMiniCart) {
        navigate("#drawer=mini-cart", {
          replace: true,
          preventScrollReset: true,
        });
      }
    }
  }, basket.fetcher);

  return (
    <div className="flex w-full flex-1-0-0 flex-row gap-[2px] self-stretch">
      {!productInCart?.quantity && (
        <div className="flex">
          <ProductQuantitySelector
            productQuantity={productQuantity}
            setProductQuantity={setProductQuantity}
            selectTriggerClassName={selectTriggerClassName}
            disabled={isLoading || isOutOfStock}
          />
        </div>
      )}
      <div className=" w-full gap-2">
        {productInCart &&
        productInCart.quantity &&
        typeof productInCart.entryNumber === "number" ? (
          <ProductCountAdjust
            productInCart={productInCart}
            key={productInCart.quantity}
          />
        ) : (
          <TriButton
            type="submit"
            disabled={isLoading || isOutOfStock}
            className={cn(
              "h-[44px] w-[inherit] animate-popupStrong rounded-bl-none rounded-tl-none text-base font-extrabold hover:border-neutral-2 hover:bg-neutral-2 md:px-2 lg:px-3",
              triBtnClassName,
            )}
            isLoading={isLoading}
            name="action"
            value="add"
            onClick={() => {
              /* assume all attributes selected from here */
              basket.add(
                {
                  productId,
                },
                parseInt(productQuantity),
              );

              addToCartEvent?.();
            }}
            loadingIndicatorClassName="fill-brand-primary-black"
          >
            <span className="flex items-center gap-2 whitespace-nowrap">
              <CartStyledIcon iconFill={isOutOfStock ? "#848484" : "white"} />
              {isOutOfStock ? "Out of stock" : "Add to Cart"}
            </span>
          </TriButton>
        )}

        {/* This is the "add to wich list" button once the wish list functionality is added we can return it*/}
        {/* <button
    type="button"
    className="flex w-10 items-center justify-center rounded-md py-2 pl-4 text-gray-400 hover:text-gray-500"
  >
    <HeartIcon
      className="h-5 w-5 flex-shrink-0"
      aria-hidden="true"
    />
    <span className="sr-only">
      Add to favorites
    </span>
  </button> */}
      </div>
    </div>
  );
};

export default AddToCartCTA;
