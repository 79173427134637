import { useCallback } from "react";

import type { SerializeFrom } from "@remix-run/cloudflare";

import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import type { Image } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useURL } from "~/contexts";

type ProductDetailsHystoryState = {
  product: SerializeFrom<AlgoliaSearchResultHit>;
  image?:
    | Image
    | {
        format?: string;
        url: string;
      };
  quickNav?: SerializeFrom<AlgoliaSearchResultHit>[];
  totalProducts?: number;
  direction?: "forward" | "back";
};

export const useProductTileData = (
  product: AlgoliaSearchResultHit,
  quickNav?: AlgoliaSearchResultHit[],
  totalProducts?: number,
) => {
  const url = useURL();

  const getHystoryState = useCallback(
    (image?: {
      format?: string;
      url?: string;
    }): ProductDetailsHystoryState | undefined => {
      if (!product) return undefined;
      return {
        product: product as SerializeFrom<typeof product>,
        image,
        quickNav: (quickNav?.filter(Boolean) ||
          []) as SerializeFrom<AlgoliaSearchResultHit>[],
        totalProducts,
      };
    },
    [product, quickNav, totalProducts],
  );
  return {
    getHystoryState,
    url,
  };
};
