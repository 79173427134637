import { useRef, useState } from "react";

import { XMarkIcon } from "@heroicons/react/20/solid";

import type { InputFieldProps } from "~/components/forms/Form";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/forms/Form";
import { Button } from "~/components/ui/button";
import { AustralianFlagIcon } from "~/components/ui/icons/australian-flag-icon";
import { Input } from "~/components/ui/input";
import { P } from "~/components/ui/text";

import { dialingCode } from "../../account+/consts";

const FormPhoneInputField = ({
  name,
  label,
  description,
  placeholder,
  ...props
}: InputFieldProps) => {
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<
    string | number | readonly string[] | undefined
  >(props.defaultValue);

  const clearInput = (focusInput = true) => {
    if (phoneInputRef?.current) {
      phoneInputRef.current.value = "";
      focusInput && phoneInputRef.current.focus();
    }
    setInputValue("");
  };

  return (
    <FormField name={name}>
      <FormItem>
        {label && (
          <FormLabel className="text-contrast-black text-sm font-semibold">
            {`${label}`}
          </FormLabel>
        )}

        <div className="flex items-end gap-1">
          <div className="flex h-[52px] flex-row items-center gap-1 rounded-[4px] border border-neutral-6 bg-neutral-10 pl-2 pr-[14.5px] text-base font-semibold">
            <AustralianFlagIcon />
            <P>{dialingCode}</P>
          </div>

          <div className="relative w-full">
            <FormControl>
              <Input
                ref={phoneInputRef}
                placeholder={placeholder}
                onChange={e => {
                  setInputValue(e.target.value);
                }}
                {...props}
              />
            </FormControl>
            {inputValue !== "" && !props.readOnly && (
              <div className="absolute right-2 top-5 flex items-center transition-opacity">
                <Button
                  variant="linkInline"
                  onClick={() => {
                    clearInput();
                  }}
                >
                  <XMarkIcon className="h-5 w-5 text-brand-primary-black" />
                </Button>
              </div>
            )}
          </div>
        </div>

        {description && <FormDescription>{description}</FormDescription>}
        <FormMessage />
      </FormItem>
    </FormField>
  );
};

export default FormPhoneInputField;
