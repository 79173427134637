import { Suspense } from "react";

import { Await } from "@remix-run/react";

import type { Cart } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { ScrollArea } from "~/components/ui/scrollarea";
import { Skeleton } from "~/components/ui/skeleton";
import { isGiftCard } from "~/lib/utils/giftcard";
import { invariant } from "~/lib/utils/utils";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";
import { useAccountData } from "~/routes/($locale)+/account+/_layout";

import { useBasket } from "../../hooks/useBasket";
import { useLoadRewards } from "../../member-rewards";
import MiniCartCMS from "./mini-cart-cms";
import MiniCartGiftCardProductItem from "./mini-cart-gift-card-product-item";
import MiniCartProductItem from "./mini-cart-product-item";

type Props = {
  basket: Cart;
};
const MiniCartProductsList = ({ basket }: Props) => {
  const {
    fetcher,
    fetcher: { data: fetcherData },
  } = useLoadRewards();
  const { miniCartStockData } = useBasket() ?? {};
  const { isLoyaltyMember } = useRootLayoutData();
  const accountData = useAccountData();
  const isLoading = fetcher.state !== "idle";
  const rewardsContent = (() => {
    // TODO: add a case to display content for Rewards Member, when it is integrated
    let content;
    if (accountData.user) {
      content = fetcherData?.rewardsContent.items.find(
        x => x.fields.name === "RewardsBannerRegistered",
      );
    } else {
      content = fetcherData?.rewardsContent.items.find(
        x => x.fields.name === "RewardsBannerGuest",
      );
    }

    return content || fetcherData?.rewardsContent.items[0];
  })();

  return (
    <Suspense>
      <Await resolve={miniCartStockData}>
        {miniCartStockData => (
          <ScrollArea className="grow basis-0">
            {(basket.entries ?? [])
              .concat()
              // Reverse the order of the products so that the most recent product is at the top
              .reverse()
              .map(productBasketEntry => {
                const product = productBasketEntry.product;
                invariant(product, "Product is required");
                const isProductGiftCard = isGiftCard(product);

                if (isProductGiftCard) {
                  return (
                    <MiniCartGiftCardProductItem
                      key={`${product.code}-gift-card-${productBasketEntry.entryNumber}`}
                      product={product}
                      productBasketEntry={productBasketEntry}
                    />
                  );
                }
                return (
                  <MiniCartProductItem
                    key={product.code}
                    product={product}
                    stockData={
                      miniCartStockData
                        ? miniCartStockData.stockList?.find(
                            stock => stock.productCode === product.code,
                          )
                        : undefined
                    }
                    productBasketEntry={productBasketEntry}
                  />
                );
              })}

            {isLoading && !isLoyaltyMember ? (
              <div className="my-6 rounded-md px-6">
                <Skeleton className="h-32" />
              </div>
            ) : (
              fetcherData?.rewardsContent.items &&
              rewardsContent &&
              !isLoyaltyMember && <MiniCartCMS content={rewardsContent} />
            )}
          </ScrollArea>
        )}
      </Await>
    </Suspense>
  );
};

export default MiniCartProductsList;
