import type { ReactNode } from "react";
import { useEffect } from "react";

import { useFetcher } from "@remix-run/react";
import type { SerializeFrom } from "@remix-run/server-runtime";
import { defer, json } from "@remix-run/server-runtime";

import { algoliaProductSearch } from "~/algolia/algolia-product-search.server";
import { getSessionServerContext } from "~/commerce-sap/.server/sessions.server";
import { getClient } from "~/contentful/.server/contentful.server";

import type {
  AlgoliaSearchResultHit,
  Category,
  PriceRangeMap,
} from "~/algolia/algolia.types";
import { EXCLUDED_CATEGORY_CODES_SEARCH_SUGGESTIONS } from "~/algolia/constants";
import type { TypeGenericTemplateSkeleton } from "~/contentful/compiled";
import { GENERIC_TEMPLATE } from "~/contentful/contents";
import { SEARCH_EVENT, useGTMTracker } from "~/google-tagmanager";

import { CATALOG_CONFIG } from "../../../commerce-sap/.server/config";

export type AlgoliaSearchSuggestion = {
  query: string;
  objectID: string;
  nb_words: number;
  "categories.id": string[];
};

export type ProductSuggestions = {
  products: AlgoliaSearchProductSuggestion[];
  totalProducts: number;
};

export type AlgoliaSearchProductSuggestion = {
  breadcrumb?: AlgoliaSearchResultHit["breadcrumb"];
  objectID: string;
  name: string;
  code: string;
  image?: string | null;
  price: number;
  priceRangeMap?: PriceRangeMap;
  promotionPrice?: number | null;
  memberPrice: number | null;
  categories: Category[];
};

const encodeCatName = (catName: string) => {
  let encodedText = catName.replace(/ /g, "-");
  encodedText = encodedText.replace(/ -/g, "--");
  return encodeURIComponent(encodedText);
};

export const loader = async ({ request, context }: LoaderArgs) => {
  const searchParams = new URL(request.url).searchParams;
  const q = searchParams.get("q");
  if (!q) {
    throw json({});
  }
  const { env } = getSessionServerContext();
  const { ALGOLIA_QUICK_SEARCH_SUGGESTIONS_INDEX_NAME } = env;

  const suggestedTerms = algoliaProductSearch({
    q,
    specifiedIndexName: ALGOLIA_QUICK_SEARCH_SUGGESTIONS_INDEX_NAME,
    hitsPerPage: 4,
    page: 0,
    clientIp: context.ip,
  }).then(({ hits }) => hits);

  const productSuggestions: Promise<ProductSuggestions> = algoliaProductSearch({
    q,
    hitsPerPage: 4,
    page: 0,
    clientIp: context.ip,
  }).then(({ hits, nbHits }): ProductSuggestions => {
    return {
      products: hits.reduce((prev: AlgoliaSearchProductSuggestion[], curr) => {
        prev.push({
          breadcrumb: curr.breadcrumb,
          objectID: curr.objectID,
          code: curr.code,
          image: Array.isArray(curr.images)
            ? curr.images.find(
                (i: { format: string; url: string }) => i.format === "product",
              )?.url
            : null,
          name: curr.name,
          price: curr.rrPrice?.[0]?.value ?? "",
          memberPrice:
            (curr?.memberprice && curr.memberprice?.[0].value) ?? null,
          promotionPrice: curr.promotionPrice?.value ?? null,
          priceRangeMap: curr.priceRangeMap ?? null,
          categories: curr.categories ?? null,
        });

        return prev;
      }, []),
      totalProducts: nbHits,
    };
  });

  const categoriesSuggestions = suggestedTerms.then(hits => {
    const categories = hits[0]?.["categories.id"];

    return categories
      ?.filter(cat => {
        return !EXCLUDED_CATEGORY_CODES_SEARCH_SUGGESTIONS.some(id =>
          cat.startsWith(`${id}||`),
        );
      })
      .map(cat => {
        const [id, name] = cat.split("||");
        return {
          id,
          name: name,
          basePath:
            encodeCatName(CATALOG_CONFIG.categoryName) +
            "/" +
            encodeCatName(name),
        };
      });
  });

  //TODO: Same with contentfull pages, they should come from algolia.
  const client = getClient(false);
  const pagesSuggestions = client
    .getEntries<TypeGenericTemplateSkeleton>({
      content_type: GENERIC_TEMPLATE,
      limit: 1,
      include: 10,
      query: q,
    })
    .then(pages => {
      return pages.items
        .map(page => ({
          name: page.fields.name,
          //TODO: will be removed
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          url: page.fields.slug ?? "/",
        }))
        .slice(0, 3);
    });

  const data = Promise.all([
    suggestedTerms,
    productSuggestions,
    pagesSuggestions,
    categoriesSuggestions,
  ]);
  return defer({
    suggestions: data,
  });
};

let searchTimeout: NodeJS.Timeout | null = null;

export const SearchSuggestionsResource = ({
  q,
  children,
}: {
  q: string;
  children: (
    data: SerializeFrom<typeof loader> | undefined,
    state: "idle" | "loading" | "submitting",
  ) => ReactNode;
}) => {
  const { data, state, load } = useFetcher<typeof loader>({
    key: "search-suggestions",
  });
  const { trackEvent } = useGTMTracker();

  useEffect(() => {
    if (!q || q.length < 3) return;
    load("/resources/search-suggestions?q=" + encodeURIComponent(q));

    if (searchTimeout) clearTimeout(searchTimeout);

    // Don't trigger on every letter changed but when the user stops typing
    searchTimeout = setTimeout(() => {
      if (!q.trim().length) return;

      trackEvent({
        event: SEARCH_EVENT,
        search_term: q,
      });
    }, 1000);
  }, [q]);

  useEffect(() => {
    if (!data) return;
    if (!q || q.length < 3) return;

    const blurrableElements = document.querySelectorAll("#blurrable-content");
    const backdrop = document.querySelector("#backdrop-quick-search");
    const headerContent = document.querySelector("#nav-content");

    if (blurrableElements.length && !q)
      if (backdrop) {
        backdrop.classList.add("hidden");
      }
    blurrableElements.forEach(
      e => e.classList.remove("blur", "pointer-events-none"),
      headerContent?.classList.remove(
        "hover:bg-transparent",
        "focus-within:bg-transparent",
      ),
    );
    if (blurrableElements.length && data) {
      if (backdrop) {
        backdrop.classList.remove("hidden");
      }
      blurrableElements.forEach(
        e => e.classList.add("blur", "pointer-events-none"),
        headerContent?.classList.add(
          "hover:bg-transparent",
          "focus-within:bg-transparent",
        ),
      );
    }
  }, [q, data]);

  return children(data, state);
};
